@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	color: $black;

	width: 100%;

	min-width: 15rem;
	max-width: 30rem;

	position: relative;
}

.select_button.select_button {
	background-color: $white;
	padding: 1rem;
	width: 100%;

	border-radius: 0.5rem;
	border: 1px solid #d3d3d3;

	> span {
		display: flex;
		align-items: center;
		width: 100%;
		gap: 0.5rem;
	}
}

.label_sub {
	color: #a0a0a0;
}

.icon_wrapper {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: flex-end;

	transition: all 0.2s ease-in-out;
}

.dropdown_list {
	position: absolute;

	top: 100%;
	left: 0;
	right: 0;

	z-index: 10;

	list-style: none;
	background-color: $white;
	margin: 0.5rem 0 0 0;
	padding: 0.5rem;

	border-radius: 0.5rem;

	box-shadow: 0 0 16px 0 rgba(109, 109, 109, 0.2);

	transition: all 0.2s ease-in-out;

	display: flex;
	flex-direction: column;
	gap: 0.25rem;
}

.dropdown_label {
	display: flex;
	align-items: center;
	width: 100%;
	gap: 0.5rem;
	padding: 1rem;

	border-radius: 0.5rem;

	background: $white;

	&:hover,
	&:focus-within {
		background-color: #f1f2f4;
	}

	&[data-selected='true'] {
		background-color: $brand-royal-10;
	}

	transition: all 0.2s ease-in-out;
}
